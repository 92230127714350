import React from 'react'
import { graphql } from 'gatsby'
import Layout, { Main } from '../wrappers/layout'
import { ProductListingGrid } from '../components/product-listing'
import { Sections } from '../components/sections'
import { GatsbyImage } from '../components/image'
import { MakerList } from '../components/maker-list'
import { SWATCHES } from '../components/constants'

function Hero ({ gatsbyImageData }) {
	return (
		<section>
			<div className="hero-image-wrapper guttersx2 outerx2">
				<GatsbyImage data={gatsbyImageData} />
			</div>
		</section>
	)
}

export default function MakerPage({ data }) {
	
	const { 
		name, 
		shopifyCollection, 
		sections, 
		heroImage, 
		places,
		backgroundColor
	} = data?.maker || {}

	return (
		<Layout backgroundColor={backgroundColor}>
			<Main className="outerx2">
				{heroImage && <Hero gatsbyImageData={heroImage.large} />}

				<hgroup className="align-center outerx2">
					<h1>{name}</h1>
					<em>{places?.map(p => p.name).join(' & ')}</em>
				</hgroup>

				<Sections className="innerx2 mbx4" content={sections} />

				{shopifyCollection && shopifyCollection?.[0]?.products?.length > 0 && (
					<div className="page-gutters">
						<div className="bdr-t">
							<h1 className="outerx4 align-center">In stock</h1>
							<ProductListingGrid products={shopifyCollection?.[0]?.products} />
						</div>
					</div>
				)}

				<div className="page-gutters outerx4">
					<div className="bdr-t">
						<h1 className="outerx4 align-center">Meet the makers</h1>
					</div>
				</div>
				<MakerList backgroundColor={SWATCHES[backgroundColor || "gold"]} />
			</Main>
		</Layout>
	)
}

export { MakerPageMeta as Head } from "../components/meta"

export const query = graphql`

  query($slug: String!) {
		maker: contentfulMaker(slug: { eq: $slug }) {
			name
			slug
			places {
				name
			}
			heroImage {
				...ContentfulMedia
			}
			sections {
				... on Node {
					...SectionsFragment
				}
			}
			shopifyCollection {
				products {
					...ProductCard
				}
			}
			backgroundColor
		}
  }
`